/**
 * Code from the below medium post, only updated for latest material UI, using a
 * Menu for the popup and with breakpoints that work.
 *
 * https://medium.com/@habibmahbub/create-appbar-material-ui-responsive-like-bootstrap-1a65e8286d6f
 */
import React from "react";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ButtonAppBarCollapse from "./ButtonAppBarCollapse";
import { Link as RouterLink } from "react-router-dom";

const styles = theme => ({
    root: {
        position: "absolute",
        right: 0
    },
    buttonBar: {
        [theme.breakpoints.down("xs")]: {
            display: "none"
        },
        margin: "10px",
        paddingLeft: "16px",
        right: 0,
        position: "relative",
        width: "100%",
        background: "transparent"
    },
    textColor: {
        color: "#ed1c24"
    }
});

const AppBarCollapse = props => (
    <div className={props.classes.root}>
        <ButtonAppBarCollapse></ButtonAppBarCollapse>
        <div className={props.classes.buttonBar} id="appbar-collapse">
            <Button color="inherit" component={RouterLink} to="/about-us">
                <div className={props.classes.textColor}>About</div>
            </Button>
            <Button color="inherit" component={RouterLink} to="/partner">
                <div className={props.classes.textColor}>Partner</div>
            </Button>
            <Button color="inherit" component={RouterLink} to="/driver">
                <div className={props.classes.textColor}>Driver</div>
            </Button>
        </div>
    </div>
);

export default withStyles(styles)(AppBarCollapse);
