import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, CircularProgress } from "@material-ui/core";

const styles = (theme) => ({
    root: {
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    content: {
        alignItems: "center",
        justifyContent: "center",
        marginLeft: 20,
    },
    circular: {
        marginLeft: 10,
    },
    typo: {
        marginTop: 10,
    },
});

function Loader(props) {
    const { classes, color } = props;
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <CircularProgress
                    color={color === undefined ? "secondary" : color}
                    thickness={5}
                    className={classes.circular}
                />
                <Typography variant="h6" className={classes.typo} color={color}>
                    Loading...
                </Typography>
            </div>
        </div>
    );
}

Loader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Loader);
