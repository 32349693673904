import React, { Suspense, useEffect, useState } from "react";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import {
    searchQuery,
    _retrieveEncryptData,
    _retrieveSession,
} from "../../utils/localStorage";

import Header from "./Header";
import TreeView from "./TreeView";
import Loader from "../../components/Loader";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    topBar: {
        zIndex: 2,
        position: "relative",
    },
    container: {
        marginTop: 70,
        display: "flex",
        flex: "1 1 auto",
        overflow: "hidden",
    },
    navBar: {
        zIndex: 3,
        width: 256,
        minWidth: 256,
        flex: "0 0 auto",
    },
    content: {
        overflowY: "auto",
        flex: 1,
        backgroundColor: theme.palette.background.default,
    },
}));

const DriverDashboard = (props) => {
    const { route, history } = props;
    const [data, setData] = useState({});

    useEffect(() => {
        let mounted = true;

        const requestInfo = async () => {
            const user_role = await _retrieveEncryptData("@doorstep:user_role");
            const url2 = "/auth/login";
            const authenticated = await _retrieveSession("@doorstep:logged");
            if (!authenticated) {
                history.push(url2);
            }
            // setAuth(authenticated);
            const response = await searchQuery(
                `usermenu?user_role=${user_role}`
            );
            // console.log(response)
            if (mounted) {
                if (response.data.status.status === 205) {
                    setData(response.data.data);
                }
            }
        };
        requestInfo();
        return () => {
            mounted = false;
        };
    }, [history]);

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <CssBaseline />
            <Header className={classes.topBar} />
            <div className={classes.container}>
                <TreeView data={data} history={route} />
                <main className={classes.content}>
                    <Suspense fallback={<Loader />}>
                        {renderRoutes(route.routes)}
                    </Suspense>
                </main>
            </div>
        </div>
    );
};

DriverDashboard.propTypes = {
    route: PropTypes.object,
};

export default DriverDashboard;
