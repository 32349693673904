export const ADD_RECORD = "ADD_RECORD";
export const DELETE_RECORD = "DELETE_RECORD";
export const FETCH_RECORD = "FETCH_RECORD";
export const UPDATE_RECORD = "UPDATE_RECORD";
export const ERROR_GENERATED = "ERROR_GENERATED";

//constant message
const SUCCESS = "green darken-2 rounded";
const FAIL = "red darken-2 rounded";
const NOT_FOUND = "orange darken-2 rounded";

export const MSG_ADD_SUCCESS = ["Record has beed added.", SUCCESS];
export const MSG_UPDATE_SUCCESS = ["Record has beed updated.", SUCCESS];
export const MSG_DELETE_SUCCESS = ["Record has beed deleted.", SUCCESS];
export const MSG_SEARCH_NOFOUND = ["No records founded", NOT_FOUND];

export const MSG_FAIL = ["Page cannot load", FAIL];
