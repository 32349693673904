import React, { Fragment, Suspense } from "react";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import Topbar from "./Topbar";
import Loader from "../../components/Loader";

const useStyles = makeStyles(theme => ({
    content: {
        height: "100%",
        paddingTop: 56,
        [theme.breakpoints.up("sm")]: {
            paddingTop: 64
        }
    }
}));

const Main = props => {
    const { route } = props;
    const classes = useStyles();

    return (
        <Fragment>
            <Topbar />
            <main className={classes.content}>
                <Suspense fallback={<Loader />}>
                    {renderRoutes(route.routes)}
                </Suspense>
            </main>
        </Fragment>
    );
};

Main.propTypes = {
    route: PropTypes.object
};

export default Main;
