import { ADD_RECORD, DELETE_RECORD, UPDATE_RECORD, FETCH_RECORD, ERROR_GENERATED } from "../action/types";

export const reducer = (state = [], action) => {
	switch (action.type) {
		case ADD_RECORD:
			return state;

		case DELETE_RECORD:
			return state;

		case UPDATE_RECORD:
			return state;

		case FETCH_RECORD:
			return action.payload;
		case ERROR_GENERATED:
			return state;

		default:
			return state;
	}
};

export const reducer_msg = (state = [], action) => {
	switch (action.type) {
		case ADD_RECORD:
			return action.payload.data;
		case UPDATE_RECORD:
			return action.payload.data;

		case DELETE_RECORD:
			return action.payload.data;

		default:
			return state;
	}
};

//check out
export const checkout = (state = [], action) => {
	switch (action.type) {
		case "CHECKOUT_ADD":
			return [...state, action.payload];
		case "CHECKOUT_CLEAR":
			return [];
		case "CHECKOUT_REMOVE":
			state.splice(action.payload, 1);
			return state;
		default:
			return state;
	}

	// console.log(action)
	// return state;
};

export const user_address = (state = [{ main_text: "Shipping to current location" }], action) => {
	switch (action.type) {
		case "USER_ADDRESS_ADD":
			return [action.payload];
		default:
			return state;
	}
	// return state;
};

export const add_product = (state = [], action) => {
	switch (action.type) {
		case "ADD_PRODUCT":
			return [...state, action.payload];
		default:
			return state;
	}
	// return state;
};

//reservation
export const reducer_booking = (state = [], action) => {
	switch (action.type) {
		case "FETCH_BOOKING":
			return action.payload;
		default:
			return state;
	}
};
