/**
 * Code from the below medium post, only updated for latest material UI, using a
 * Menu for the popup and with breakpoints that work.
 *
 * https://medium.com/@habibmahbub/create-appbar-material-ui-responsive-like-bootstrap-1a65e8286d6f
 */
import React from "react";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ButtonAppBarCollapse from "./ButtonAppBarCollapse";
import { Link as RouterLink } from "react-router-dom";
import SecureLS from "secure-ls";

const styles = theme => ({
    root: {
        position: "absolute",
        right: 0
    },
    buttonBar: {
        [theme.breakpoints.down("sm")]: {
            display: "none"
        },
        margin: "10px",
        paddingLeft: "16px",
        right: 0,
        position: "relative",
        width: "100%",
        background: "transparent"
    },
    textColor: {
        color: "#ed1c24"
    }
});

const AppBarCollapse = props => {
    const handleLogout = () => {
        const ls = new SecureLS();
        ls.removeAll();
        sessionStorage.clear();
    };
    return (
        <div className={props.classes.root}>
            <ButtonAppBarCollapse></ButtonAppBarCollapse>
            <div className={props.classes.buttonBar} id="appbar-collapse">
                <Button
                    component={RouterLink}
                    to="/auth/login"
                    color="inherit"
                    onClick={handleLogout}
                >
                    <div className={props.classes.textColor}>Logout</div>
                </Button>
            </div>
        </div>
    );
};

export default withStyles(styles)(AppBarCollapse);
