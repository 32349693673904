import React from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider as StoreProvider } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { renderRoutes } from "react-router-config";
import CookiesNotification from "./components/CookiesNotification";
import "./App.css";

import theme from "./theme";
import store from "./store/index";
import routes from "./routes";
import ScrollReset from "./components/ScrollReset";
// import "./mixins/moment";
// import "./mixins/validate";
// import "./mixins/prismjs";
import "./assets/scss/index.scss";
const history = createBrowserHistory();
const App = () => {
    return (
        <StoreProvider store={store}>
            <ThemeProvider theme={theme}>
                <Router history={history}>
                    <ScrollReset />
                    <CookiesNotification />
                    {renderRoutes(routes)}
                </Router>
            </ThemeProvider>
        </StoreProvider>
    );
};

export default App;
