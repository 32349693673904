import React from "react";
import ReactDOM from "react-dom";
import { SnackbarProvider } from "notistack";

import App from "./App";
// import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <SnackbarProvider>
        <App />
    </SnackbarProvider>,
    document.getElementById("root")
);

// serviceWorker.unregister();
