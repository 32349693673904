import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AppBarCollapse from "./ButtonAppBar";
import Logo from "../../assets/images/Logo.png";

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    navigation: {},
    toggleDrawer: {},
    appTitle: {
        fontSize: 18,
        paddingLeft: 10,
        color: "#ed1c24",
        fontWeight: 900,
        [theme.breakpoints.down("xs")]: {
            fontWeight: 500,
            fontSize: 15
        },
        [theme.breakpoints.down("sm")]: {
            fontWeight: 900,
            fontSize: 15
        }
    },
    image: {
        width: 70,
        height: 40,
        [theme.breakpoints.down("xs")]: {
            width: 40,
            height: 20
        },
        [theme.breakpoints.down("sm")]: {
            width: 55,
            height: 30
        }
    }
});

function Header(props) {
    const { classes } = props;
    return (
        <AppBar position="fixed" color="default">
            <Toolbar>
                <img alt="logo" src={Logo} className={classes.image} />
                <Typography color="inherit" className={classes.appTitle}>
                    DOORSTEP
                </Typography>
                <AppBarCollapse />
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Header);
