import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from "@material-ui/lab/TreeItem";
import { withRouter } from "react-router";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        // height: 240,
        flexGrow: 1,
        maxWidth: 250,
        backgroundColor: "white",
        padding: 10,
    },
});

function FileSystemNavigator(props) {
    const classes = useStyles();
    if (Object.keys(props.data).length === 0) return null;
    return (
        <TreeView className={classes.root}>
            {props.data.menu.map((item, index) => (
                <Fragment key={index}>
                    {item.href !== "" ? (
                        <TreeItem
                            nodeId={String(item.id)}
                            key={item.id}
                            onClick={() =>
                                props.history.push("/admin/settings")
                            }
                            label={
                                <Typography variant="h6" gutterBottom>
                                    {item.main_text}
                                </Typography>
                            }
                            style={{ paddingBottom: 10, fontWeight: "bold" }}
                        ></TreeItem>
                    ) : (
                        <TreeItem
                            nodeId={String(item.id)}
                            key={item.id}
                            label={
                                <Typography variant="h6" gutterBottom>
                                    {item.main_text}
                                </Typography>
                            }
                            style={{ paddingBottom: 10, fontWeight: "bold" }}
                        >
                            {item.menu_level.map((sub, index1) => (
                                <TreeItem
                                    nodeId={String(sub.id)}
                                    key={sub.id}
                                    label={sub.title}
                                    onClick={() => props.history.push(sub.href)}
                                    style={{ paddingTop: 10, paddingLeft: 20 }}
                                ></TreeItem>
                            ))}
                        </TreeItem>
                    )}
                </Fragment>
            ))}
        </TreeView>
    );
}

export default withRouter(FileSystemNavigator);
