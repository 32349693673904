import axios from "axios";
export const socketUrl = "https://api.doorsteps.tech";
export const apiUrl = "https://api.doorsteps.tech";
// export const apiUrl = "http://10.0.0.104:5000";
// export const socketUrl = "http://10.0.0.104:5000";

const CancelToken = axios.CancelToken;
export const signal = CancelToken.source();

export const options = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        cancelToken: signal.token,
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_WEB_API_KEY,
        crossDomain: true,
    },
};

export const encrypt = {
    encodingType: "aes",
    isCompression: false,
    encryptionSecret: process.env.REACT_APP_ENCRYPT_KEY,
};

export const api_key = {
    api_key: process.env.REACT_APP_MAP_API_KEY,
};
