/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import { lazy } from "react";
import UserLayout from "./layout/user/Main";
import DoorstepLayout from "./layout/doorstep/DoorstepDashboard";

const routes = [
    {
        path: "/admin",
        component: DoorstepLayout,
        routes: [
            {
                path: "/admin/customer-settlement",
                exact: true,
                component: lazy(() => import("./views/customer/Settlement")),
            },
            {
                path: "/admin/customer-list",
                exact: true,
                component: lazy(() => import("./views/customer/ListCustomer")),
            },
            {
                path: "/admin/driver-list",
                exact: true,
                component: lazy(() => import("./views/driver/ListDriver")),
            },
            {
                path: "/admin/driver-settlement",
                exact: true,
                component: lazy(() =>
                    import("./views/driver/DriverSettlement")
                ),
            },
            {
                path: "/admin/location-list",
                exact: true,
                component: lazy(() => import("./views/doorstep/LocationList")),
            },
            {
                path: "/admin/push-rule-list",
                exact: true,
                component: lazy(() => import("./views/doorstep/PushRuleList")),
            },
            {
                path: "/admin/settings",
                exact: true,
                component: lazy(() => import("./views/doorstep/Settings")),
            },
            {
                path: "/admin/delivery-list",
                exact: true,
                component: lazy(() =>
                    import("./views/doorstep/delivery/ListDelivery")
                ),
            },
            {
                path: "/admin/account-balance",
                exact: true,
                component: lazy(() =>
                    import("./views/doorstep/account/AccountList")
                ),
            },
            {
                path: "/admin/account-statement",
                exact: true,
                component: lazy(() =>
                    import("./views/doorstep/account/AccountStatement")
                ),
            },
            {
                path: "/admin/transaction-type",
                exact: true,
                component: lazy(() =>
                    import("./views/doorstep/account/TransactionList")
                ),
            },
            {
                path: "/admin/adjustment-account",
                exact: true,
                component: lazy(() =>
                    import("./views/doorstep/account/AdjustmentFund")
                ),
            },
            {
                path: "/admin/stock-assignment",
                exact: true,
                component: lazy(() =>
                    import("./views/doorstep/delivery/ListInstock")
                ),
            },
            {
                path: "/admin/new-stock-assignment",
                exact: true,
                component: lazy(() =>
                    import("./views/doorstep/delivery/ListInstockNew")
                ),
            },
            {
                path: "/admin/daily-report",
                exact: true,
                component: lazy(() =>
                    import("./views/doorstep/delivery/DailyList")
                ),
            },
            {
                path: "/admin/parcel-report",
                exact: true,
                component: lazy(() =>
                    import("./views/doorstep/delivery/ParcelReport")
                ),
            },
            {
                path: "/admin/assign-booking",
                exact: true,
                component: lazy(() =>
                    import("./views/doorstep/delivery/AssignBooking")
                ),
            },
            {
                path: "/admin/stock-controller",
                exact: true,
                component: lazy(() =>
                    import("./views/doorstep/delivery/StockController")
                ),
            },
            {
                path: "/admin/update-zone",
                exact: true,
                component: lazy(() =>
                    import("./views/doorstep/delivery/UpdateZone")
                ),
            },
            {
                path: "/admin/instock-detail",
                exact: true,
                component: lazy(() =>
                    import("./views/doorstep/delivery/InStockDetail")
                ),
            },
            {
                path: "/admin/delete-record",
                exact: true,
                component: lazy(() => import("./views/doorstep/DeleteRecord")),
            },
            {
                path: "/admin/update-driver",
                exact: true,
                component: lazy(() => import("./views/doorstep/UpdateDriver")),
            },
            {
                path: "/admin/remove-status",
                exact: true,
                component: lazy(() => import("./views/doorstep/RemoveStatus")),
            },
            {
                path: "/admin/current-driver-location",
                exact: true,
                component: lazy(() =>
                    import("./views/driver/CurrentDriverLocation")
                ),
            },
            {
                path: "/admin/settlement-history",
                exact: true,
                component: lazy(() =>
                    import("./views/customer/SettlementHistory")
                ),
            },
            {
                path: "/admin/dashboard",
                exact: true,
                component: lazy(() => import("./views/doorstep/Dashboard")),
            },
            {
                path: "/admin/chat",
                exact: true,
                component: lazy(() =>
                    import("./views/doorstep/chat/ConversationMainPage")
                ),
            },
            {
                path: "/admin/chat/:id",
                exact: true,
                component: lazy(() =>
                    import("./views/doorstep/chat/ConversationMainPage")
                ),
            },
            {
                path: "/admin/driver-delivered-parcel",
                exact: true,
                param: 'driver_id',
                component: lazy(() =>
                    import("./views/driver/delivered/ListDeliveredParcel")
                ),
            },
            {
                path: "/admin/sender-delivered-parcel",
                exact: true,
                param: 'sender_id',
                component: lazy(() =>
                    import("./views/driver/delivered/ListDeliveredParcel")
                ),
            }
        ],
    },

    {
        path: "/auth",
        component: UserLayout,
        routes: [
            {
                path: "/auth/login",
                exact: true,
                component: lazy(() => import("./views/user/Login")),
            },
            {
                path: "/auth/validateotp",
                exact: true,
                component: lazy(() => import("./views/user/OTP")),
            },
            {
                path: "/auth/setpassword",
                exact: true,
                component: lazy(() => import("./views/user/SetPassword")),
            },
        ],
    },
    {
        path: "/",
        component: UserLayout,
        routes: [
            {
                path: "/",
                exact: true,
                component: lazy(() => import("./views/home/Index")),
            },
            {
                path: "/terms",
                exact: true,
                component: lazy(() => import("./views/termsCondition/Terms")),
            },
            {
                path: "/privacy",
                exact: true,
                component: lazy(() => import("./views/termsCondition/Privacy")),
            },
            {
                path: "/delivery-terms",
                exact: true,
                component: lazy(() =>
                    import("./views/termsCondition/DeliveryTerm")
                ),
            },
            {
                path: "/contact-us",
                exact: true,
                component: lazy(() => import("./views/ContactUs")),
            },
            {
                path: "/about-us",
                exact: true,
                component: lazy(() => import("./views/AboutUs")),
            },
            {
                path: "/partner",
                exact: true,
                component: lazy(() => import("./views/user/Register")),
            },
            {
                path: "/driver",
                exact: true,
                component: lazy(() => import("./views/user/Register")),
            },
        ],
    },
];

export default routes;
