import axios from "axios";
import { apiUrl, options, encrypt, socketUrl } from "../config";
import SecureLS from "secure-ls";
import moment from "moment";
import io from "socket.io-client";

export const _retrieveData = async (key) => {
    try {
        const value = await localStorage.getItem(key);
        return value;
    } catch (error) {
        // Error retrieving data
    }
};

export const _storeData = async (key, value) => {
    try {
        await localStorage.setItem(key, value);
    } catch (error) {
        // Error saving data
    }
};

export const _storeEncryptData = async (key, value) => {
    try {
        const ls = new SecureLS(encrypt);
        await ls.set(key, value);
    } catch (error) {
        // Error saving data
    }
};
export const _retrieveEncryptData = async (key) => {
    try {
        const ls = new SecureLS(encrypt);
        const value = await ls.get(key);
        return value;
    } catch (error) {
        // Error saving data
    }
};

export const _storeSession = async (key, value) => {
    try {
        await sessionStorage.setItem(key, value);
    } catch (error) {
        // Error saving data
    }
};

export const _retrieveSession = async (key) => {
    try {
        const value = await sessionStorage.getItem(key);
        return value;
    } catch (error) {
        // Error saving data
    }
};

export function searchQuery(param) {
    return axios.get(`${apiUrl}/${param}`, options).catch(function (thrown) {
        if (axios.isCancel(thrown)) {
            console.log("Request canceled", thrown.message);
        } else {
            // handle error
        }
    });
}
export function getUri(param) {
    try {
        return `${apiUrl}/getFile/?file=${param}`;
    } catch (error) {
        // return require("../../assets/images/Image300.png");
    }
}
// post data
export async function postRecord(fields, routeTo, paRam = "") {
    return await axios.post(`${apiUrl}/${routeTo}`, fields, options);
}

export function timeFromNow(param) {
    let date = [
        moment(param).year(),
        moment(param).month(),
        moment(param).date(),
        moment(param).hour(),
        moment(param).minute(),
        moment(param).second(),
    ];
    return date;
}

export const socket = io(`${socketUrl}/private`, {
    // transports: ["websocket"],
    serveClient: false,
    // below are engine.IO options
    origins: "*:*",
    transports: ["websocket", "polling"],
    pingInterval: 10000,
    pingTimeout: 5000,
    cookie: false,
    autoConnect: true,
});

//short date and time
export function stringToTimeChat(timespan) {
    let diff = (Date.now() - moment(timespan)) / 1000;
    let format = "";
    if (diff > 0 && diff < 3600) {
        format = `${moment(timespan).format("LT")}`;
    } else {
        format = `${moment(timespan).format("MMM DD")}`;
    }
    return format;
}
