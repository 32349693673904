import { combineReducers } from "redux";
import { reducer, reducer_msg, checkout, user_address, add_product, reducer_booking } from "./reducer";

const rootReducer = combineReducers({
	data: reducer,
	_text: reducer_msg,
	checkout: checkout,
	user_address: user_address,
	product: add_product,
	_booking: reducer_booking
});

export default rootReducer;
